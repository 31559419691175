.productPageContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: rgb(80, 76, 76);
}

h3 {
    color: black;
}
